import _objectSpread from "/Users/itnew000/Desktop/ofht20230915/htcode/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _toConsumableArray from "/Users/itnew000/Desktop/ofht20230915/htcode/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import Common from '../../assets/js/common.js';
import Sortable from 'sortablejs'; //引入eltable表格拖拽排序插件
//弹框的
import { contextTypeData, auth } from '../../data/object/object';
import { uploadFile } from '../../api/qiniuApI';
import UploadLogo from '../common/UploadLogo';
import EditorU from '../../components/popup/EditorUeditor.vue';
//活动管理
import Active from './Active.vue';
export default {
  name: "CommonDrawer",
  props: ['baseType', 'drawerModuleState', 'originalData'],
  data: function data() {
    return {
      currentLeftData: {
        "id": 4,
        "name": "活动",
        "stdlevelId": 4,
        "rank": 1
      },
      localCourse: JSON.parse(sessionStorage.getItem("course-" + this.$route.query.courseId)),
      /*编辑器开始*/
      isEditorShow: false,
      ueidtorName: 'ueditor',
      val: '',
      modeType: 'descript',
      nowArticleData: '',
      itemName: 'content',
      // 编辑器返回drawData的字段
      /*编辑器结束*/
      /*弹框里面资源文件的参数开始*/
      resourceTable: {
        table: [{
          name: '图片1',
          url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
          size: '10kb',
          rank: 1,
          accessType: 1 //1游客 2注册用户 3正式用户
        }, {
          name: '图片1',
          url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
          size: '10kb',
          rank: 1,
          accessType: 1 //1游客 2注册用户 3正式用户
        }, {
          name: '图片1',
          url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
          size: '10kb',
          rank: 1,
          accessType: 1 //1游客 2注册用户 3正式用户
        }, {
          name: '图片1',
          url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
          size: '10kb',
          rank: 1,
          accessType: 1 //1游客 2注册用户 3正式用户
        }, {
          name: '图片1',
          url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
          size: '10kb',
          rank: 1,
          accessType: 1 //1游客 2注册用户 3正式用户
        }]
      },

      addfileList: [],
      //文件资源列表
      fileArr: [],
      //传递完成的文件列表数量
      /*弹框里面资源文件的参数结束*/
      /*弹框里面的参数开始*/
      auth: auth,
      //浏览权限列表
      contextTypeData: contextTypeData,
      moduleRules: {
        contextType: [{
          required: true,
          message: '请选择模块类型',
          trigger: 'change'
        }],
        cname: [{
          required: true,
          message: '请输入中文标题',
          trigger: 'blur'
        }] /*,
           content:[
            { required: true, message: '请输入图文内容' }
           ]*/
      },

      //模块的校验规则

      moduleForm: {
        courseId: this.$route.query.courseId,
        cname: '',
        ename: '',
        rank: 1,
        state: 1,
        //状态：1正常 -1禁用
        content: '',
        //图文内容
        baseId: '',
        //上级节点ID：被添加的目标概览ID
        baseType: this.baseType,
        //上级节点类型：1目标概览、2目标领域、3关键经验、4教-学路径
        contextType: '',
        //模块类型：1图文内容 2资源列表 3标签列表 4活动列表
        fileList: 1,
        /*文件列表，数组类型，如果contextType为2时，必须有值
        数组包含字段：name, url, ext, cover, size, duration, accessType, description
        accessType类型为：1游客 2注册用户 3正式用户*/
        actList: [],
        /*活动列表，数组类型，如果contextType为4时，必须有值
        数组字段：courseId, baseId, contextId, activityId, activityName, */
        description: '' //描述 暂时用不到
      },

      //模块的数据
      activeName: 'resource',
      //设置弹框的选项卡 module 模块信息 resource 资源管理 tag 标签管理 activity 活动管理
      addModuleDialog: false //是否显示添加弹框的对话框
      /*弹框里面的参数结束*/
    };
  },
  created: function created() {},
  components: {
    UploadLogo: UploadLogo,
    EditorU: EditorU,
    Active: Active
  },
  mounted: function mounted() {
    // 阻止默认行为
    document.body.ondrop = function (event) {
      event.preventDefault();
      event.stopPropagation();
    };
    /* this.$nextTick(()=>{
       setTimeout(()=>{
         //开启拖拽排序
         this.setSort();
       },1000)
      })*/
  },

  methods: {
    //下移
    downResource: function downResource(index) {
      var nums = this.addfileList;
      nums[index] = nums.splice(index + 1, 1, nums[index])[0];
      this.addfileList = _toConsumableArray(nums);
    },
    //上移
    upResource: function upResource(index) {
      var nums = this.addfileList;
      nums[index] = nums.splice(index - 1, 1, nums[index])[0];
      this.addfileList = _toConsumableArray(nums);
    },
    //切换tab
    changeTab: function changeTab(tab) {
      var convert = JSON.parse(JSON.stringify(tab.props));
      console.log("切换tab", convert);
      switch (convert.name) {
        case "":
          //this.activeName = "module";
          break;
        case "resource":
          this.moduleForm.contextType = 3;
          //this.activeName = "resource";
          break;
        case "tab":
          this.moduleForm.contextType = 4;
          // this.activeName = "tab";
          break;
        case "activity":
          this.moduleForm.contextType = 5;
          //this.activeName = "activity";
          break;
      }
    },
    //删除资源
    deleteResource: function deleteResource(index) {
      // console.log("删除资源",row);
      this.addfileList.splice(index, 1);
    },
    //表格拖拽排序 行拖拽
    setSort: function setSort() {
      var tbody = document.querySelector('.j-resource-table tbody');
      var _this = this;
      Sortable.create(tbody, {
        onEnd: function onEnd(_ref) {
          var newIndex = _ref.newIndex,
            oldIndex = _ref.oldIndex;
          if (newIndex == oldIndex) return;
          _this.addfileList.splice(newIndex, 0, _this.addfileList.splice(oldIndex, 1)[0]);
          var newArray = _this.addfileList.slice(0);
          _this.addfileList = [];
          _this.$nextTick(function () {
            _this.addfileList = newArray;
          });
        }
      });
    },
    //模块类型下来框改变
    changeModuleType: function changeModuleType() {
      //设置弹框的选项卡 module 模块信息 resource 资源管理 tag 标签管理 activity 活动管理
      switch (parseInt(this.moduleForm.contextType)) {
        case 1:
          this.activeName = "";
          break;
        case 2:
          this.activeName = "";
          break;
        case 3:
          this.activeName = "resource";
          break;
        case 4:
          this.activeName = "tab";
          break;
        case 5:
          this.activeName = "activity";
          break;
        case 6:
          //视频列表，同时把图文禁用
          this.activeName = "resource";
          break;
      }
      if (this.moduleForm.contextType == 2 || this.moduleForm.contextType == 1) {
        this.activeName = "";
      } else if (this.moduleForm.contextType) {}
    },
    //重设数据
    setData: function setData(name, json) {
      var _this2 = this;
      this[name] = json;
      if (this.drawerModuleState == "edit") {
        //改变默认视图
        this.changeModuleType();
        //如果是编辑
        this.addfileList = this.moduleForm.fileList;
        console.log("设置活动", this.moduleForm.actList);
        if (this.moduleForm.contextType == 5) {
          //如果是活动安排的类型就设置活动列表
          this.$nextTick(function () {
            _this2.$refs.refActive.setActiveData(_this2.moduleForm.actList);
          });
        }

        //if(this.moduleForm.actList.length > 0){
        //}
        console.log("弹框里面的资源文件变化", this.addfileList);
      }
    },
    //删除文件
    onRemoveFn: function onRemoveFn() {},
    //单个的 ui对象转为原生file
    cloneFileSingle: function cloneFileSingle(file) {
      var arr = this.addfileList;
      // for (let i = 0; i < fileList.length; i++) {
      //  let file=fileList[i];
      var json = _objectSpread({}, file);
      json.percent = -1;
      //浏览权限默认为游客
      json.accessType = 1;
      //描述默认为空
      json.description = "";
      //封面默认为空
      json.cover = "";

      //如果是文件夹的话就是传课件，那就不读取时长了，否则性能一般的电脑浏览器会卡死崩溃
      //if(this.activeName != "folder"){
      //搞视频、音频时长
      if (Common.checkResourceType(file.name) == "audio" || Common.checkResourceType(file.name) == "mp4") {
        var url = URL.createObjectURL(file.raw);
        var fileElement = new Audio(url);
        fileElement.addEventListener("loadedmetadata", function (_event) {
          json.duration = Math.ceil(fileElement.duration);
        });
      }
      //}
      arr.push(json);
      //  }
      return arr;
    },
    //element ui对象转为原生file 代码有bug，比如选择同时3个或者以上视频的时候会导致重复的出现，所以不用了
    /* cloneFile(fileList){
       let arr=this.addfileList;
       for (let i = 0; i < fileList.length; i++) {
         let file=fileList[i];
         let json={
           ...fileList[i]
         }
         json.percent=-1;
         //浏览权限默认为游客
         json.accessType=1;
         //描述默认为空
         json.description="";
         //封面默认为空
         json.cover="";
          //如果是文件夹的话就是传课件，那就不读取时长了，否则性能一般的电脑浏览器会卡死崩溃
         //if(this.activeName != "folder"){
           //搞视频、音频时长
           if(Common.checkResourceType(file.name) == "audio" || Common.checkResourceType(file.name) == "mp4"){
             const url = URL.createObjectURL(file.raw);
             const fileElement = new Audio(url)
             fileElement.addEventListener("loadedmetadata",function (_event){
               json.duration=Math.ceil(fileElement.duration);
             })
           }
         //}
         arr.push(json);
       }
       return arr;
     },*/
    //传递完封面的回调函数
    setLogo: function setLogo(cover, row) {
      this.currentRow = row;
      this.currentRow.cover = cover;
      console.log("this.currentRow", this.addfileList);
      /*      this.formFile={
              ...this.currentRow
            }
            this.currentRow.cover=cover;
            // console.log("this.currentRow",this.currentRow)
            if(this.currentRow.url){
              //传资源文件的话要保证content为空
              this.formFile.content="";
              let arr=[{
                url:this.currentRow.url,
                size:this.currentRow.size,
                name:this.currentRow.name,
                duration:this.currentRow.duration
              }]
              this.formFile.urlList=arr;
            }else{
              //传图文的话urllist要保证为空
              this.formFile.urlList=[];
            }
            this.formFile.cover=cover;
            //调用修改封面接口
            this.editCourseResourceCover();*/
    },
    //修改封面接口
    editCourseResourceCover: function editCourseResourceCover() {
      console.log("修改封面接口");
    },
    //上传文件
    addResourceUploadFile: function addResourceUploadFile(formEl, upJson) {
      var _this3 = this;
      //设置资源上传完成钱都不可点击
      this.isFlag = true;
      var dateTime = new Date().getTime();
      var _this = this;
      var _loop = function _loop(i) {
        var file = _this3.addfileList[i];
        var _index = i;
        if (!file.url || file.url == "") {
          uploadFile({
            folderName: "resource",
            dateTime: dateTime,
            //唯一值
            duration: file.duration,
            //唯一值
            index: _index,
            //唯一值
            isHideLoading: true,
            //上传过程中是否显示遮罩层
            complete: function complete(res, url) {
              //_this.formFile.url=url;
              //普通文件
              var json = {
                url: url,
                size: res.size,
                name: res.name,
                duration: res.duration
              };
              //上传完成file数组
              _this.fileArr.push(json);
              //给addfileList传url
              _this.addfileList[res.index].url = url;
              // _this.formFile.urlList.push(json);
              //如果全部都传完了的话就添加到数据库
              console.log("_this.addfileList.length", _this.addfileList.length, _this.fileArr.length);
              // return;
              if (_this.addfileList.length == _this.fileArr.length) {
                console.log("_this.addfileList", _this.addfileList);
                //调用添加数据
                // _this.$emit("confirm",formEl)
                //关闭限制不可点击的按钮状态
                _this.isFlag = false;
                //开启上传url的状态
                _this.subbmitState = true;
              }
              //添加资源
            },
            //进度
            next: function next(res, subscription) {
              /* let json={
                 name:"name",
                 total:res.total
               }
               _this.fileListProgress[i]=json;*/
              _this.subscription = subscription;
              var percent = res.total.percent;
              _this.addfileList[i].percent = Math.floor(percent * 100) / 100;
              // console.log("_this.addfileList[i].percent",_this.addfileList[i].percent)
            }
          }, file.raw);
        }
      };
      for (var i = 0; i < this.addfileList.length; i++) {
        _loop(i);
      }
    },
    //上传文件列表变动
    changeFile: function changeFile(file, fileList) {
      this.addfileList = this.cloneFileSingle(file);
      console.log("改变发生时上传的文件", this.addfileList);
    },
    //
    cancelModuleClick: function cancelModuleClick() {
      //this.$nextTick(function () {
      this.addfileList = [];
      this.moduleForm.actList = [];
      //重置数据
      this.$refs["ruleFormRef"].resetFields();
      //});
      this.addModuleDialog = false;
    },
    //活动列表转为可以添加的数据
    activityListToData: function activityListToData() {
      var arrList = this.$refs.refActive.getActiveData();
      var arr = [];
      for (var i = 0; i < arrList.length; i++) {
        var oJson = arrList[i];
        var json = {
          courseId: this.$route.query.courseId,
          baseId: this.moduleForm.baseId,
          id: oJson.id,
          name: oJson.name,
          rank: i
        };
        if (this.drawerModuleState === "edit") {
          json.contextId = this.moduleForm.id; //编辑会有
        }

        arr.push(json);
      }
      return arr;
    },
    //确定添加模块
    confirmAddModule: function confirmAddModule() {
      var _this4 = this;
      var that = this;
      /*      console.log("this.addFileList",this.addfileList,that.moduleForm,that.$refs.refActive.getActiveData())*/
      this.$refs['ruleFormRef'].validate(function (valid) {
        var url = "";
        if (valid) {
          if (that.drawerModuleState === "edit") {
            //修改
            // url = "editModuleContext";
            url = _this4.originalData.editUrl;
          } else {
            //添加课程类型
            // url = "addModuleContext";
            url = _this4.originalData.addUrl;
          }

          //资源文件或者视频列表都需要把资源添加进去
          if (that.moduleForm.contextType == 3 || that.moduleForm.contextType == 6) {
            console.log("点击确定时上传的文件", that.addfileList);
            //把资源文件添加进去
            that.moduleForm.fileList = that.addfileList;
            for (var i = 0; i < that.moduleForm.fileList.length; i++) {
              if (!that.moduleForm.fileList[i].url || that.moduleForm.fileList[i].url == "") {
                that.$toast.error("请点击“全部上传”，待上传完成后再点击“确定”");
                return;
              }
              that.moduleForm.fileList[i].rank = i + 1;
            }
          } else if (that.moduleForm.contextType == 5) {
            //把活动加进去
            that.moduleForm.actList = that.activityListToData();
          }
          that.service.axiosUtils({
            requestName: url,
            data: that.moduleForm,
            sucFn: function sucFn(res) {
              if (res.status == 1) {
                _this4.$toast.success(res.message);
                that.cancelModuleClick();
                //刷新嵌入它的父组件的视图
                _this4.$emit('initData');
              } else {
                _this4.$toast.error(res.message);
              }
            }
          });
        }
      });
    },
    //显示编辑器
    showDialog: function showDialog(type, model) {
      this.nowArticleData = {
        'content': model
      };
      // this.itemName = type
      this.isEditorShow = true;
    },
    //显示添加模块
    showAddModule: function showAddModule() {
      var _this5 = this;
      this.addModuleDialog = true;
      this.$nextTick(function () {
        //开启拖拽排序
        _this5.setSort();
      });
    },
    //隐藏添加模块
    hideAddModule: function hideAddModule() {
      this.cancelModuleClick();
      // this.addModuleDialog=false;
    },
    //修改数据
    setModuleForm: function setModuleForm(name, value) {
      this.moduleForm[name] = value;
    }
  }
};